:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: hsla(0, 0%, 100%, 0.7);
  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);
  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;
  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;
  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;
  --toastify-color-progress-light: linear-gradient(90deg,
      #4cd964,
      #5ac8fa,
      #007aff,
      #34aadc,
      #5856d6,
      #ff2d55);
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
  --color-accent: 0, 159, 127;
  --color-accent-hover: 1, 147, 118;
  --color-accent-300: 153, 217, 204;
  --color-accent-400: 77, 188, 165;
  --color-accent-500: 0, 159, 127;
  --color-accent-600: 0, 143, 114;
  --color-accent-700: 0, 119, 95;
  --color-light: 255, 255, 255;
  --color-dark: 0, 0, 0;
  --color-muted-black: 17, 17, 17;
  --text-base: 107, 114, 128;
  --text-base-dark: 75, 85, 99;
  --text-muted: 156, 163, 175;
  --text-muted-light: 209, 213, 219;
  --text-sub-heading: 55, 65, 81;
  --text-heading: 31, 41, 55;
  --text-bolder: 31, 41, 55;
  --color-border-50: 249, 250, 251;
  --color-border-100: 243, 244, 246;
  --color-border-200: 229, 231, 235;
  --color-border-base: 209, 213, 219;
  --color-border-400: 156, 163, 175;
  --color-gray-50: 249, 250, 251;
  --color-gray-100: 243, 244, 246;
  --color-gray-200: 229, 231, 235;
  --color-gray-300: 209, 213, 219;
  --color-gray-400: 156, 163, 175;
  --color-gray-500: 107, 114, 128;
  --color-gray-600: 75, 85, 99;
  --color-gray-700: 55, 65, 81;
  --color-gray-800: 31, 41, 55;
  --color-gray-900: 31, 41, 55;
}

:root {
  --color-accent: 0, 159, 127;
  --color-accent-hover: 1, 147, 118;
  --color-accent-300: 153, 217, 204;
  --color-accent-400: 77, 188, 165;
  --color-accent-500: 0, 159, 127;
  --color-accent-600: 0, 143, 114;
  --color-accent-700: 0, 119, 95;
  --color-light: 255, 255, 255;
  --color-dark: 0, 0, 0;
  --color-muted-black: 17, 17, 17;
  --text-base: 107, 114, 128;
  --text-base-dark: 75, 85, 99;
  --text-muted: 156, 163, 175;
  --text-muted-light: 209, 213, 219;
  --text-sub-heading: 55, 65, 81;
  --text-heading: 31, 41, 55;
  --text-bolder: 31, 41, 55;
  --color-border-50: 249, 250, 251;
  --color-border-100: 243, 244, 246;
  --color-border-200: 229, 231, 235;
  --color-border-base: 209, 213, 219;
  --color-border-400: 156, 163, 175;
  --color-gray-50: 249, 250, 251;
  --color-gray-100: 243, 244, 246;
  --color-gray-200: 229, 231, 235;
  --color-gray-300: 209, 213, 219;
  --color-gray-400: 156, 163, 175;
  --color-gray-500: 107, 114, 128;
  --color-gray-600: 75, 85, 99;
  --color-gray-700: 55, 65, 81;
  --color-gray-800: 31, 41, 55;
  --color-gray-900: 31, 41, 55;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  --tw-ring-inset: var(--tw-empty,
      /*!*/
      /*!*/
    );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-blur: var(--tw-empty,
      /*!*/
      /*!*/
    );
  --tw-brightness: var(--tw-empty,
      /*!*/
      /*!*/
    );
  --tw-contrast: var(--tw-empty,
      /*!*/
      /*!*/
    );
  --tw-grayscale: var(--tw-empty,
      /*!*/
      /*!*/
    );
  --tw-hue-rotate: var(--tw-empty,
      /*!*/
      /*!*/
    );
  --tw-invert: var(--tw-empty,
      /*!*/
      /*!*/
    );
  --tw-saturate: var(--tw-empty,
      /*!*/
      /*!*/
    );
  --tw-sepia: var(--tw-empty,
      /*!*/
      /*!*/
    );
  --tw-drop-shadow: var(--tw-empty,
      /*!*/
      /*!*/
    );
  --tw-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

::-webkit-scrollbar {
  width: 4px;
  border-radius: 50%;
  direction: ltr;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

textarea:focus,
input:focus {
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-border-opacity: 1;
  border-color: rgba(var(--color-accent), var(--tw-border-opacity));
}

:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: hsla(0, 0%, 100%, 0.7);
  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);
  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;
  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;
  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;
  --toastify-color-progress-light: linear-gradient(90deg,
      #4cd964,
      #5ac8fa,
      #007aff,
      #34aadc,
      #5856d6,
      #ff2d55);
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}

:root {
  --color-accent: 0, 159, 127;
  --color-accent-hover: 1, 147, 118;
  --color-accent-300: 153, 217, 204;
  --color-accent-400: 77, 188, 165;
  --color-accent-500: 0, 159, 127;
  --color-accent-600: 0, 143, 114;
  --color-accent-700: 0, 119, 95;
  --color-light: 255, 255, 255;
  --color-dark: 0, 0, 0;
  --color-muted-black: 17, 17, 17;
  --text-base: 107, 114, 128;
  --text-base-dark: 75, 85, 99;
  --text-muted: 156, 163, 175;
  --text-muted-light: 209, 213, 219;
  --text-sub-heading: 55, 65, 81;
  --text-heading: 31, 41, 55;
  --text-bolder: 31, 41, 55;
  --color-border-50: 249, 250, 251;
  --color-border-100: 243, 244, 246;
  --color-border-200: 229, 231, 235;
  --color-border-base: 209, 213, 219;
  --color-border-400: 156, 163, 175;
  --color-gray-50: 249, 250, 251;
  --color-gray-100: 243, 244, 246;
  --color-gray-200: 229, 231, 235;
  --color-gray-300: 209, 213, 219;
  --color-gray-400: 156, 163, 175;
  --color-gray-500: 107, 114, 128;
  --color-gray-600: 75, 85, 99;
  --color-gray-700: 55, 65, 81;
  --color-gray-800: 31, 41, 55;
  --color-gray-900: 31, 41, 55;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Open Sans, system-ui, sans-serif;
  margin: 0px;
  box-sizing: border-box !important;
}

datalist {
  display: inline;
}

input[type="file"] {
  display: none;
}

html,
#root {
  background: #f3f4f6;
  margin: 0;
  padding: 0;
  box-sizing: border-box !important;
  height: auto;
}

button,
input[type="submit"],
input[type="reset"] {
  background: none;
  color: inherit;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

/* Panier Part */
.s-panier {
  /* position: absolute;
  display: flex;
  width: 28rem;
  height: 100%;
  z-index: 60; */
  background-color: white;
  border-radius: 25px;


}

.s-panier-w {
  max-width: 28rem;
  width: 100%;
  height: 100%;
}

.h-panier {

  background-color: rgba(var(--color-light), var(--tw-bg-opacity));
  --tw-border-opacity: 0.75;
  border-color: rgba(var(--color-border-200), var(--tw-border-opacity));
  border-bottom-width: 1px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  z-index: 10;
  top: 0;
  padding: 10px;

}

.panier-checkout {
  bottom: 0;

  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  background-color: rgba(var(--color-light), 1);
  max-width: 28rem;
  border: none;
  box-shadow: none;
  appearance: none;
  border-width: 0px;
  height: 3.5rem;
}

.panier-checkout div {
  height: 3rem;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, -webkit-text-decoration-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding: 0.25rem;
  background-color: rgba(var(--color-accent), 1);
  border-radius: 9999px;
  justify-content: space-between;
  width: 100%;
  display: flex;
  cursor: pointer;
}

.checkout-panier {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  color: white;
}

.panier-total {
  color: rgba(var(--color-accent), 1);
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  background-color: rgba(var(--color-light), 1);
  border-radius: 9999px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.t-i-panier {
  --tw-text-opacity: 1;
  color: rgba(var(--color-accent), var(--tw-text-opacity));
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.t-i-panier span {
  margin-left: 0.5rem;
}

.content-panier {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.panier-content {
  padding-top: 4rem;
  padding-bottom: 5rem;

  flex-grow: 1;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  background-color: white;
}

.panier-items {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-color: rgba(var(--color-border-200), 1);
  border-style: solid;
  border-bottom-width: 1px;
  border-top-width: 1px;
  border-left-width: 0px;
  border-right-width: 0px;
  align-items: center;
  display: flex;
}

.item-c-p {
  flex-shrink: 0;
  display: flex;
  width: 24rem;
}

.item-c-content {
  color: rgba(var(--text-heading), 1);
  background-color: rgba(var(--color-gray-100), 1);
  border-radius: 9999px;
  overflow: hidden;
  align-items: center;
  flex-direction: column-reverse;
  width: 2rem;
  height: 6rem;
  display: flex;
}

.item-c-content>span {
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hidden {
  display: none;
}

.btn-qte {
  height: 100%;
  width: 100%;
  padding: 0.5rem;
  cursor: pointer;
  -webkit-appearance: button;
  background-color: transparent;
  background-image: none;
  text-transform: none;
  border: none;
  font-family: inherit;
  font-size: 100%;
  line-height: inherit;
  color: inherit;
  margin: 0;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, -webkit-text-decoration-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.2s;
}

.panier-close {
  transition-duration: 0.2s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  color: rgba(var(--text-muted), 1);
  background-color: rgba(var(--color-gray-100), 1);
  border-radius: 9999px;
  justify-content: center;
  align-items: center;
  width: 1.75rem;
  height: 1.75rem;
  display: flex;
  cursor: pointer;

}

.panier-close:hover {
  background-color: rgba(var(--color-accent), 1);
  color: white;
}



.item-p-img {
  justify-content: center;
  align-items: center;
  display: flex;
  margin-left: 1rem;
  margin-right: 1rem;
}

.item-p-img img {
  width: 4rem;
  height: 4rem;
}

.item-c-detail {}

.item-c-detail span {
  color: rgba(var(--text-heading), 1);
  font-weight: 700;
  font-family: Open Sans, system-ui, sans-serif;
}

.item-c-q {
  font-size: 10px;
  font-weight: 200 !important;
  color: #888;
}

.item-c-price {
  color: rgba(var(--color-accent), 1);
  font-weight: 600;
}

.item-c-total {
  margin-left: auto;
  color: rgba(var(--text-heading), 1);
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
}

.item-c-del {
  margin-right: -0.5rem;
  margin-left: 0.75rem;
  transition-duration: 0.2s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  color: rgba(var(--text-muted), 1);
  border-radius: 9999px;
  flex-shrink: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.item-c-del:hover {
  transition-duration: 0.2s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  color: red;
}



/* Menu mobile  */
.s-menu {
  position: absolute;
  display: flex;
  width: 28rem;
  height: 100%;
  z-index: 60;
  background-color: white;
  top: 0;
  left: 0;
  flex-direction: column;
}

.header-s-menu {
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100%;
  height: 4rem;
}

.close-menu {
  direction: rtl;
}

.s-menu-container {
  display: flex;
  flex-direction: column;
}

.s-menu-container span {
  padding: 0.75rem;
  cursor: pointer;
  font-weight: 500;
}

.s-menu-container span a {
  text-decoration: none;
  color: #121212;
}

@media (max-width: 400px) {
  .s-panier {
    width: 100% !important;
  }

  .h-panier {
    max-width: none;
    left: 0;
    right: 0;
  }

  .s-menu {
    width: 100%;
  }

  .panier-items {
    padding: 0.5rem;
  }

  .item-c-total {
    margin-left: 0;
  }



  .panier-checkout div {
    width: auto;
  }

  .panier-checkout {
    width: auto;
  }
}

/* Article sdolo  */

.article-s {
  position: fixed;
  top: 0;

  z-index: 80;
  max-width: 72rem;
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  padding: 2rem;
}

.close-menu1 {
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 1.5rem;
  padding-top: 1rem;
}

.art-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vw;
  position: absolute;
  top: 0;
  left: 0;
}

.article-s-content {
  height: 100%;
  width: 100%;
  position: relative;
  background-color: white;
  border-radius: 0.5rem;
  flex-direction: row;
  display: flex;
}

.a-s-detail,
.a-s-images {
  padding: 3.5rem;
  width: 50%;
}

@media (max-width: 700px) {
  .article-s {
    overflow: scroll;
  }

  .pro-btn-add,
  .pro-btn-min {
    margin-bottom: 0px !important;
  }

  .article-s-content {
    flex-direction: column;
    height: auto;
  }

  .a-s-detail,
  .a-s-images {
    width: -webkit-fill-available;
    padding: 2rem;
  }

  .close-menu1 {
    padding-right: 1rem;
    padding-top: 0.5rem;
  }

  .a-s-qte {
    flex-direction: column-reverse;
  }

  .a-s-qte>span {
    text-align: center;
    margin-bottom: 10px;
  }
}

.a-s-offers {
  /* margin-bottom: 2.5rem; */
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.a-s-offers span {
  margin-left: auto;
  color: rgba(var(--color-light), 1);
  line-height: 1.5rem;
  font-weight: 600;
  font-size: 0.75rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  background-color: rgb(234, 179, 8, 1);
  border-radius: 9999px;
}

.a-s-images img {
  height: 60%;
  width: 100%;
}

.a-s-d span {
  color: rgba(var(--text-base), 1);
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.a-s-unit {
  margin-top: 0.75rem;
  color: rgba(107, 114, 128, 1);
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.a-s-price {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  align-items: center;
  display: flex;
  font-size: 1.875rem;
  line-height: 2.25rem;
  text-decoration-line: none;
  color: rgba(var(--color-accent), 1);
  font-weight: 600;
}

.a-s-qte {
  display: flex;
}

.a-s-qte span {
  margin-left: 10px;
}

.a-s-category {
  padding-top: 1.5rem;
  margin-top: 1.5rem;
  border-color: rgba(var(--color-border-200), 0.6);
  border-top-width: 1px;
  align-items: flex-start;
  flex-direction: row;
  width: 100%;
  display: flex;
}

.a-s-cats {
  flex-wrap: wrap;
  flex-direction: row;
  display: flex;
}

.a-s-category>span {
  margin-right: 1.5rem;
}

.a-s-cats>button {
  margin-right: 0.5rem;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, -webkit-text-decoration-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
  color: rgba(var(--text-heading), 1);
  letter-spacing: 0.05em;
  text-transform: lowercase;
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  background-color: transparent;
  border-color: rgba(var(--color-border-200), 1);
  border-width: 1px !important;
  border-radius: 5px;
  white-space: nowrap;
  margin-bottom: 0.5rem;
  cursor: pointer;
  -webkit-appearance: button;
  background-image: none;
  box-shadow: none;
}

.a-s-cats>button:hover {
  color: rgba(var(--color-accent), 1);
  border-color: rgba(var(--color-accent), 1);
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, -webkit-text-decoration-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}

.p404 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.logo-404 {
  padding: 1rem;
}

.logo_login {
  height: 50px;
}

.p404 h3 {
  color: rgba(var(--text-base), 1);
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.75rem;
  text-align: center;
  width: 100%;
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
}

.panier-vide {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 320px;
  width: 100%;
  flex-direction: column;
}

.ins-container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  padding-top: 8rem;
  flex-direction: column;
  background: #fff;
}

.ins-container>.btn-login,
.ins-container>.login-row {
  width: 20rem;
}

.error {
  padding: 1rem;
  margin-top: 4px;
  color: #ba3939;
  background: #ffe0e0;
  border: 1px solid #a33a3a;
  border-radius: 5;
}

.checkout-page {
  overflow: scroll;
  background-color: #f3f4f6;
  padding: 2rem;
  padding-top: 6rem;
  display: flex;
  flex-direction: column;
}

.checkout-page h3 {
  color: rgba(var(--color-accent), 1);

  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2rem;
  border-bottom: 1px solid rgba(var(--color-accent), 1);
}

.checkout-detail {
  background: white;
  display: flex;
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  background-color: rgba(var(--color-light), 1);
  border-radius: 5px;
}

.checkout-from,
.box-order {
  width: 50%;
}

.form-row {
  display: flex;
  padding-right: 2rem;
  padding-left: 2rem;
  justify-content: center;
}

.form-row .login-row {
  width: 90%;
}

.box-order {
  background: #ededed;
  padding: 30px 40px 45px;
}

.checkout-from {
  padding: 30px 40px 45px;
}

.box-order-total {
  display: flex;
  justify-content: end;
  font-size: 20px;
  padding: 1rem;
}

.box-order-total span {
  padding-left: 0.25rem;
  font-weight: 900;
  color: rgba(var(--color-accent), 1);
}

.box-paiement {
  display: flex;
  flex-direction: column;
}

.box-paiement-titre {
  font-weight: 20px;
}

.box-paiement-desc {
  padding: 1em;
}

.box-pay {
  background: rgba(var(--color-accent), 1);
  border-color: rgba(var(--color-accent), 1);
  color: #fff;
  font-size: 18px;
  margin-top: 20px;
  text-transform: uppercase;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  cursor: pointer;
}

.merci-commande {
  color: rgba(var(--color-accent), 1);
  width: -webkit-fill-available;
  padding: 2rem;
  padding-top: 8rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.merci-commande img {
  height: 100px;
  width: 100px;
}

.back-home {
  position: fixed;
  bottom: 10px;
  left: 10px;
  font-size: 40px;
}

.back-home a {
  color: rgba(var(--color-accent), 1);
}

.livreur-command {
  padding: 2rem;
  width: -webkit-fill-available;
}

.join {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(var(--color-gray-900), 0.5);
  z-index: 50;
}

.model-join {
  width: 100%;
  margin-top: 20px;
  display: flex;

  z-index: 100;

  justify-content: center;
}

.container-model {
  background-color: rgba(var(--color-light), 1);
  height: auto;
  border-radius: 0.75rem;
  max-width: 480px;
  padding: 2rem;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  display: flex;
}

@media (max-width: 400px) {
  .container-model {
    width: 100%;
    height: 100%;
  }

  /* .login-row {
    margin-bottom: 0.25rem !important;
  }
  .join-f-line {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  } */
  .container-model {
    flex-direction: column;
    justify-content: normal;
    align-items: normal;
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .articles-container {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    padding-top: 1rem !important;
    padding-bottom: 4rem !important;
  }
}

.model-logo {
  display: flex;
  justify-content: center;
}

.join-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.login-row {
  margin-bottom: 1.25rem;
}

.login-row label {
  color: rgba(var(--text-base-dark), 1);
  line-height: 1;
  font-weight: 600;
  font-size: 0.875rem;
  margin-bottom: 0.75rem;
}

.login-row input {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.3s;
  color: rgba(var(--text-heading), 1);
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding-left: 1rem;
  padding-right: 1rem;
  --tw-border-opacity: 1;
  border-color: rgba(var(--color-border-base), 1);
  border-width: 1px;
  border-radius: 5px;
  align-items: center;
  appearance: none;
  width: -webkit-fill-available;
  height: 3rem;
  display: flex;
}

.login-titre {
  font-size: 1rem;
  line-height: 1.5rem;
  margin-top: 1.25rem;
  margin-bottom: 2.5rem;
  color: rgba(var(--text-base), 1);
  text-align: center;
}

.btn-login {
  height: 3rem;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.3s;
  outline: 2px solid transparent;
  outline-offset: 2px;
  color: rgba(var(--color-light), 1);
  line-height: 1;
  font-weight: 600;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  background-color: rgba(var(--color-accent), 1);
  border-color: transparent;
  border-width: 1px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: -webkit-fill-available;
  display: inline-flex;
  cursor: pointer;
}

.join-f-line {
  margin-top: 2.75rem;
  margin-bottom: 2rem;
  color: rgba(var(--text-heading), 1);
  font-size: 0.875rem;
  line-height: 1.25rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
  position: relative;
}

.join-f-q {
  font-size: 1rem;
  line-height: 1.5rem;
  color: rgba(var(--text-base), 1);
  text-align: center;
}

.join-line {
  margin-left: 0.25rem;
  transition-duration: 0.2s;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, -webkit-text-decoration-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  text-decoration-line: underline;
  color: rgba(var(--color-accent), 1);
  font-weight: 600;
  cursor: pointer;
  -webkit-appearance: button;
  background-color: transparent;
  background-image: none;
  text-transform: none;
}

.Hscreen {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #fafafa;
}

/* @media (min-width:400px) and (max-width:900px) {
   
}​ */

@media (max-width: 900px) {
  aside {
    display: none !important;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgb(0 0 0/0.05);
  background-color: rgba(var(--color-light), 1);
  z-index: 40;
}

.LogoCategory {
  width: auto;
  display: flex;
  align-items: center;
}

.logo {
  height: 48px;
}

.logo-link {
  text-decoration: none;
}

.c-family {
  text-align: left;
  display: inline-block;
  position: relative;
  margin-inline-end: auto;
  margin-inline-start: 2.5rem;
}

.c-family-btn {
  padding-left: 1rem;
  padding-right: 1rem;
  border-width: 1px;
  min-width: 150px;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
  background-color: rgba(255, 255, 255, 1);
  border-color: rgba(var(--color-border-200), var(--tw-border-opacity));
  border-radius: 5px;
  align-items: center;
  flex-shrink: 0;
  display: flex;
  cursor: pointer;
  height: 2.75rem;
  color: rgba(0, 158, 126, 1);
  justify-content: space-between;
}

.c-btn-icons {
  /* color: #009E7E;
    opacity: 1; */
  display: flex;
  justify-content: center;
  align-content: center;
  display: flex;
  height: 1.25rem;
  width: 1.25rem;
  align-items: center;
  margin-inline-end: 0.5rem;
}

.btn-angle {
  display: flex;
  padding-top: 0.25rem;
  padding-inline-start: 0.625rem;
}

.c-family-menu {
  transform-origin: top left;
  right: auto;
  left: 0;
  height: auto;
  max-height: 18rem;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-shadow: rgba(0, 0, 0, 0.08) 0px 2px 16px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  --tw-bg-opacity: 1;
  background-color: rgba(var(--color-light), var(--tw-bg-opacity));
  border-radius: 5px;
  min-width: 12rem;
  min-height: 10rem;
  margin-top: 0.5rem;
  position: absolute;
  list-style: none;
  margin: 0;
  padding: 0;
  overflow-x: auto;
}

.c-link {
  transition-duration: 0.2s;
  --tw-text-opacity: 1;
  color: rgba(var(--text-base-dark), var(--tw-text-opacity));
  text-transform: capitalize;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  align-items: center;
  cursor: pointer;
  display: flex;
  text-decoration: none;
  border: none;
  width: 100%;
}

.c-link:hover {
  color: rgba(0, 158, 126, 1);
}

.c-link-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.25rem;
  height: 1.25rem;
}

.c-link-items {
  margin-left: 10px;
  display: flex;
  margin-left: 10px;
  align-items: center;
  justify-content: center;
}

.hidden {
  display: none !important;
}

.Menu {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  list-style: none;
}

.Menu li {
  margin-left: 2.5rem;
}

.Menu li:first-of-type {
  margin-left: 0;
}

.Menu li:last-of-type {
  margin-right: 2.5rem;
}

.Menu-link {
  transition-duration: 0.2s;
  text-decoration: none;
  --tw-text-opacity: 1;
  color: rgba(var(--text-heading), var(--tw-text-opacity));
  font-weight: 400;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.Menu-join {
  cursor: pointer;
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-text-opacity: 1;
  color: rgba(var(--color-light), var(--tw-text-opacity));
  line-height: 1;
  font-weight: 600;
  font-size: 0.875rem;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  --tw-bg-opacity: 1;
  background-color: rgba(var(--color-accent), var(--tw-bg-opacity));
  border-color: transparent;
  border-width: 1px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  height: 2.25rem;
  display: inline-flex;
}

.h-screen {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  transition-property: transform;
}

.front-page-img {
  display: block;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  box-sizing: border-box;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  box-sizing: border-box;
  padding: 0;
  border: none;
  margin: auto;
  display: block;
  width: 100%;
  height: 100%;

  object-fit: cover;
}

.front-page-img img {
  height: 100%;
  width: 100%;
}

.front-page {
  padding-left: 5rem;
  padding-right: 5rem;
  text-align: center;
  padding: 1.25rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  display: flex;

  top: 0;
  bottom: 0;
  position: absolute;
  right: 0;
  left: 0;
}

.f-title {
  font-size: 3rem;

  line-height: 1;
  --tw-text-opacity: 1;
  color: rgba(var(--text-heading), var(--tw-text-opacity));
  letter-spacing: -0.025em;
  font-weight: 700;
}

.f-subtitle {
  font-size: 1.125rem;
  line-height: 1.75rem;
  color: #999da4;
}

.f-formsearch {
  margin-top: 2rem;
  width: 50%;
}

.f-formsearch form {
  width: 100%;
}

.f-form {
  --tw-shadow: rgba(0, 0, 0, 0.05) 0px 21px 36px;
  border-radius: 0.5rem;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  height: 3.5rem;
  display: flex;
  position: relative;
}

.f-input {
  border-inline-end-width: 0;
  padding-inline-end: 3.5rem;
  padding-inline-start: 1.5rem;

  color: rgba(var(--text-heading), var(--tw-text-opacity));
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-bg-opacity: 1;
  background-color: rgba(var(--color-light), var(--tw-bg-opacity));
  border-color: transparent;
  border-width: 1px;
  border-radius: 0.5rem;
  overflow: hidden;
  appearance: none;

  width: 100%;
  display: flex;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.f-search-btn {
  --tw-text-opacity: 1;
  color: rgba(var(--color-light), var(--tw-text-opacity));
  font-weight: 600;
  padding-left: 2rem;
  padding-right: 2rem;
  --tw-bg-opacity: 1;
  background-color: rgba(var(--color-accent), var(--tw-bg-opacity));
  border-radius: 0.5rem;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  align-items: center;
  height: 100%;
  display: flex;
  cursor: pointer;
  border: none;
}

.search-cons {
  margin-inline-end: 0.625rem;
}

/*** Panier ***/
.Panier-btn {
  border-radius: 5px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  right: 0;
  display: flex;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-shadow: rgba(0, 0, 0, 0.05) 0px 21px 36px;
  --tw-text-opacity: 1;
  color: rgba(var(--color-light), var(--tw-text-opacity));
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-bg-opacity: 1;
  background-color: rgba(var(--color-accent), var(--tw-bg-opacity));
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -3rem;
  z-index: 40;
  top: 50%;
  position: fixed;
  border: none;
  cursor: pointer;
  padding: 0.75rem;
}

.p-item {
  padding-bottom: 0.125rem;
  display: flex;
}

.p-tit {
  margin-inline-start: 0.5rem;
  display: flex;
}

.p-total {
  --tw-text-opacity: 1;
  color: rgba(var(--color-accent), var(--tw-text-opacity));
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  --tw-bg-opacity: 1;
  background-color: rgba(var(--color-light), var(--tw-bg-opacity));
  border-radius: 5px;
  width: 100%;
  margin-top: 0.75rem;
}

.header-mobile {
  transition-duration: 0.3s;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-bottom: 1px solid rgba(229, 231, 235, 1);
  border-bottom-width: 1px;
  justify-content: center;
  align-items: center;
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 40;
  background-color: white;
}

.menu-mobile {
  left: 0;
  right: 0;
  display: none;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  background-color: rgba(var(--color-light), 1);
  position: fixed;
  bottom: 0;
  z-index: 40;
  justify-content: space-between;
}

.nav-mob-item {
  padding: 0.5rem;
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
  cursor: pointer;
}

.search-input-mobile {
  padding-right: 1rem;
  padding-left: 2.5rem;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.3s;
  color: rgba(var(--text-heading), 1);
  font-size: 0.875rem;
  line-height: 1.25rem;
  background-color: rgba(var(--color-gray-100), 1);
  border-color: transparent;
  border-width: 1px;
  border-radius: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  appearance: none;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-search {
  position: relative;
}

.container-search i {
  position: absolute;
  z-index: 10;
  left: 10px;
  top: 10px;
  color: #6b7280;
}

.Main {
  --tw-border-opacity: 0.7;
  border-color: rgba(var(--color-border-200), var(--tw-border-opacity));
  border-style: solid;
  border-top-width: 1px;
  flex: 1 1 0%;
  display: flex;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 600;
}

aside {
  width: 18rem;
  display: block;
  position: sticky;
  background-color: rgba(var(--color-light), 1);
}

.MuiPaper-root {
  border-radius: 0px !important;
  box-shadow: none !important;
}

.ct-img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.25rem;
  height: 1.25rem;
  -webkit-margin-end: 1rem;
  margin-inline-end: 1rem;
}

.ct-sous-cat {
  display: flex;
  flex-direction: column;
}

.ct-sous-cat span {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  align-items: center;
  -webkit-margin-start: 2.1rem;
  margin-inline-start: 2.1rem;
  cursor: pointer;
}

.ff {
  color: rgba(0, 159, 127, 1);
}

.articles-container {
  padding: 2rem;
  --tw-bg-opacity: 1;
  background-color: rgba(var(--color-gray-100), var(--tw-bg-opacity));
  flex: 1 1 0%;
}

.artlcles-grid {
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 0.75rem;
  display: grid;
}

@media (max-width: 1120px) {
  .artlcles-grid {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }
}

@media (max-width: 815px) {
  .artlcles-grid {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }
}

@media (max-width: 650px) {
  .artlcles-grid {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }
}

.product-card {
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-bg-opacity: 1;
  background-color: rgba(var(--color-light), var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(var(--color-border-200), var(--tw-border-opacity));
  border-width: 1px;
  border-radius: 5px;
  overflow: hidden;
  height: 100%;
}

.product-card:hover {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0/0.1), 0 1px 2px -1px rgb(0 0 0/0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color),
    0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transition-duration: 0.2s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}

.product-show {
  height: 16rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: auto;
  position: relative;
}

.product-s {
  display: block;
  overflow: hidden;
  position: absolute;
  inset: 0px;
  box-sizing: border-box;
  margin: 0px;
}

.product-s img {
  position: absolute;
  inset: 0px;
  box-sizing: border-box;
  padding: 0px;
  border: none;
  margin: auto;
  display: block;
  width: 0px;
  height: 0px;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  object-fit: contain;
}

.product-offer {
  right: 1rem;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  top: 1rem;
  --tw-text-opacity: 1;
  color: rgba(var(--color-light), var(--tw-text-opacity));
  line-height: 1.5rem;
  font-weight: 600;
  font-size: 0.75rem;
  --tw-bg-opacity: 1;
  background-color: rgba(var(--color-accent), var(--tw-bg-opacity));
  border-radius: 5px;
  position: absolute;
}

.header-product {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
}

.pro-pric {
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
}

.price-v {
  font-size: 1rem;
  line-height: 1.5rem;
  --tw-text-opacity: 1;
  color: rgba(var(--text-heading), var(--tw-text-opacity));
  font-weight: 600;
}

.price-nv {
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-inline-start: 0.5rem;
  --tw-text-opacity: 1;
  color: rgba(var(--text-muted), var(--tw-text-opacity));
  text-decoration: line-through;
}

.pro-tit {
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgba(var(--text-base), var(--tw-text-opacity));
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  margin-bottom: 1rem;
  font-family: Open Sans, system-ui, sans-serif;
}

.pro-add {
  font-size: 0.875rem;
  line-height: 1.25rem;
  height: 2.25rem;
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
  --tw-text-opacity: 1;
  color: rgba(var(--text-base-dark), var(--tw-text-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(var(--color-gray-100), var(--tw-bg-opacity));
  border-radius: 5px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
  cursor: pointer;
  border: none;
  padding: 0;
  z-index: 10;
}

.pro-add:hover,
.pro-btn-add:hover,
.btn-qte:hover {
  color: rgba(var(--color-light), 1);
  background-color: rgba(var(--color-accent), 1);
}

.pro-btn-text {
  flex: 1 1 0%;
}

.pro-btn-add,
.pro-btn-min {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  width: 2.25rem;
  height: 2.25rem;
  transition-duration: 0.2s;
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
  --tw-bg-opacity: 1;
  background-color: rgba(var(--color-gray-200), var(--tw-bg-opacity));
  place-items: center;
  display: grid;
}

.pro-btn-min {
  margin-left: 0 !important;
}

.aside {
  position: sticky;
}

.offers {
  padding: 2rem;
  --tw-bg-opacity: 1;
  background-color: rgba(var(--color-light), var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(var(--color-border-200), var(--tw-border-opacity));
  border-top-width: 1px;
}

.o-item {
  display: block;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  margin: 0;
}

.o-item img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  box-sizing: border-box;
  padding: 0;
  border: none;
  margin: auto;
  display: block;
  width: 0;
  height: 0;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
}

.o-i {
  display: block;
  box-sizing: border-box;
  padding-top: 46.55172413793103%;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  left: -1.25rem;
  width: 2.25rem;
  height: 2.25rem;
  margin-top: -1.25rem;
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-text-opacity: 1;
  color: rgba(var(--text-heading), var(--tw-text-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(var(--color-light), var(--tw-bg-opacity));
  --tw-border-opacity: 0.7;
  border-color: rgba(var(--color-border-200), var(--tw-border-opacity));
  border-width: 1px;
  border-radius: 9999px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  display: flex;
  top: 50%;
  z-index: 40;
  position: absolute;
  font-size: 10px !important;
}

.swiper-button-next {
  right: -1.25rem !important;
}

.pos_content {
  display: flex;
  flex-direction: row;

  padding-top: 100px;
  padding-right: 20px;
  padding-left: 20px;
}

.pos_container_category,
.pos_container_article {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.pos_cat_item {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  background-color: #fff;
  padding: 10px;
  width: 90px;
  margin-left: 20px;
  margin-bottom: 10px;


}

.pos_cat_item span,
.pos_cat_item img {
  padding: 10px;
  font-size: 16px;
  font-weight: 800;
  color: #009F7F;
}

.pos_article {
  margin-left: 10px;
  padding: 10px;
  border-radius: 25px;
  background: #fff;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  max-width: 250px;
  min-width: 250px;

}

.pos_left {
  width: 30%;
  height: 100%;
  background-color: #fff;
  border-radius: 25px;
}

.pos_right {
  width: 70%;
}

.panier_item {
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.panier_footer {
  display: flex;
  flex-direction: column;
}

::-webkit-scrollbar {
  display: none;
}


.group_min {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}

.minform input {
  margin-left: 2px;
  padding: 4px;
}

.minform {
  margin-bottom: 10px;
}


@media (max-width: 700px) {
  .ordenence {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .header,
  .Hscreen,
  .Panier-btn {
    display: none !important;
  }

  .offers {
    padding-top: 7rem !important;
  }

  .header-mobile,
  .menu-mobile {
    display: flex !important;
  }

  .pos_content {
    flex-direction: column;
  }

  .pos_right,
  .pos_left {
    width: 100%;
  }

  .pos_container_category {
    overflow: scroll;

  }

  .pos_container_article {
    overflow: scroll;
    flex-direction: column;
    margin-top: 10px;
  }

  .pos_article {
    margin-bottom: 10px;
    max-width: 100%;
  }

  .group_min {
    flex-direction: column;
  }

  .minform input {
    margin-bottom: 10px;
    margin-top: 10px;
  }
}

.print-head {

  display: flex;
  justify-content: space-between;
}

.logo-print {
  width: 50px;
  height: 50px;
}

.info-print {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  font-weight: 600;
  margin-bottom: 10px;
  border-bottom: 1px solid black;
}

.print-titre {
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.thead-print {
  border: 4px solid black;
  width: 100%;
}

.thead-print th {
  text-align: left;
}

.thead-print th:last-child,
.tbody-print td:last-child {
  text-align: right;
}

.print-table {
  width: 100%;
}

.total-print {
  margin-top: 10px;
  width: 100%;
  text-align: left;
  font-size: 14px;
  font-weight: 900;
  display: flex;
  flex-direction: column;
}

.ordenence {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.print-btn {
  text-align: center;
  padding: 10px;
  background-color: #009F7F;
  color: #fff;
  border-radius: 10px;
}

.facture_detail {
  width: 500px;
  text-align: center;
}

.remise {
  color: #DC2626
}

.avance {
  color: #009F7F
}

@media print {
  .remise {
    color: #000
  }

  .avance {
    color: #000
  }

  .hide_me {
    display: flex !important;
    flex-direction: column;
  }

  .mt {
    margin-top: 20px !important;
  }

  .facture_detail {
    width: 500px;
    text-align: center;
    padding: 10px;
    position: absolute;
    bottom: 0;


  }

  .ordenence {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .print-btn {
    text-align: center;
    padding: 10px;
    background-color: #009F7F;
    color: #fff;
    border-radius: 10px;
  }

  .print-table {
    width: 100%;
  }

  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }

  .print-head {

    display: flex;
    justify-content: space-between;
  }

  .print-titre {
    font-size: 14px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .thead-print {
    border: 4px solid black;
    width: 100%;
  }

  .thead-print th {
    text-align: left;
  }

  .thead-print th:last-child,
  .tbody-print td:last-child {
    text-align: right;
  }

  .logo-print {
    width: 50px;
    height: 50px;
  }

  .logo-right {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: right;
    align-items: flex-end;
  }

  .info-print {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    font-weight: 600;
    margin-bottom: 10px;
    border-bottom: 1px solid black;
  }

  .print-sinature {
    margin-top: 10px;
    width: 100%;
    height: 150px;

    text-align: center;
  }



  .total-print {
    margin-top: 10px;
    width: 100%;
    text-align: left;
    font-size: 14px;
    font-weight: 900;
    display: flex;
    flex-direction: column;
  }


}