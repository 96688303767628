/*  */
.container-dash2 {
  height: 100%;
  width: 100%;
}

.d-container {
  padding: 2rem;

  height: 100%;
  width: 100%;
  display: grid;
}

.info-dash {
  gap: 1.25rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

}

.dash-card {
  border-radius: 20px;
  min-width: 300px;
}

.dash-card-container {
  padding: 10px;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 5px;
  flex-direction: column;


  display: flex;
}

.dash-card-header {
  padding-bottom: 2rem;
  justify-content: space-between;
  width: 100%;
  display: flex;
  margin-bottom: auto;
}

.dash-card-num {
  color: rgba(31, 41, 55, 1);
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin-bottom: 0.5rem;
}

.dash-card-titre {
  flex-direction: column;
  width: 100%;
  display: flex;
}

.d-c-t {
  color: rgba(31, 41, 55, 1);
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 0.25rem;
}

.d-c-st {
  color: rgba(107, 114, 128, 1);
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 600;
}

.dash-card-icon {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 3rem;
  display: flex;
}

.icon-dash1 {
  color: #047857;
  background-color: rgb(167, 243, 208);
}

.icon-dash2 {
  color: #ff6e6e;
  background-color: #facaca;
}

.icon-dash3 {
  color: #ffc02d;
  background-color: #ffe8b2;
}

.icon-dash4 {
  color: #3c6de2;
  background-color: #93c5fd;
}

.icon-dash5 {
  color: #6A0DAD;
  background-color: #DDA0DD;
}

.icon-dash6 {
  color: #B30000;
  background-color: #FF8080;
}

.dash-secend {
  padding-left: 2rem;
  padding-right: 2rem;
  /* flex-wrap: wrap; */
  width: 100%;
  display: flex;
  border-radius: 5px;
  margin-bottom: 1.5rem;
}

.dash-pie {
  background-color: white;
  padding: 1rem;
  width: 30%;
}

.dash-table {
  background-color: white;
  height: 100%;
  width: 68.5%;
  margin-left: 20px;
  padding: 10px;
}

.nav-admin {
  padding-left: 2rem;
  padding-right: 2rem;
  justify-content: space-between;
  align-items: center;
  display: flex;
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  position: fixed;
  z-index: 40;
  width: -webkit-fill-available;
  background-color: white;
}

.admin-profil {
  display: flex;
  justify-content: center;
  color: rgba(var(--text-base-dark), 1);
  align-items: center;
}

.admin-profil i {
  margin-left: 10px;
  padding: 10px;
}

.admin-main {
  padding-top: 5rem;
  flex: 1 1 0%;
  display: flex;
}

.admin-aisde {
  left: 0;
  display: block;
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  overflow-y: auto;
  width: 15rem;
  height: 100%;
  position: fixed;
}

.admin-content {
  padding-inline-start: 17rem;
  width: 100%;
  height: auto;
}

.admin-menu-content {

  padding-bottom: 0.75rem;
  flex-direction: column;
  display: flex;
  cursor: pointer;
}

.admin-menu-content a {
  text-align: left;
  color: rgba(75, 85, 99, 1);
  font-size: 1rem;
  line-height: 1.5rem;
  align-items: center;
  width: 100%;
  display: flex;
  text-decoration: none;
  margin-top: 15px;
}

.admin-menu-content i {
  margin-inline-end: 1rem;
  width: 1.25rem;
  height: 1.25rem;
  display: block;
  vertical-align: middle;
  font-size: 1.25rem;
}

.admin-menu-content a:nth-child(1) {
  margin-top: 0px;
}

.admin-groups {

  padding: 2rem;
  background-color: #f3f4f6;
}

i {
  cursor: pointer;
}

.admin-g-s {
  flex-direction: row;
  padding: 2rem;
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  background-color: rgba(var(--color-light), 1);
  border-radius: 5px;
  align-items: center;
  display: flex;
  margin-bottom: 2rem;
}

.admin-g-titre {
  width: 25%;
  margin-bottom: 0;
}

.admin-g-titre h1 {
  color: rgba(var(--text-heading), 1);
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin: 0;
}

.admin-g-search {
  width: 50%;
  flex-direction: row;
  margin-inline-start: auto;
  align-items: center;
  display: flex;
}

.admin-form {
  align-items: center;
  width: 100%;
  position: relative;
}

.w-full {
  width: 80%;
}

.btn-g-add {
  margin-top: calc(0px * calc(1 - 0));
  margin-bottom: calc(0px * 0);
  margin-inline-start: 1.5rem;
  width: auto;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.3s;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform, filter, backdrop-filter,
    -webkit-backdrop-filter;
  outline: 2px solid transparent;
  outline-offset: 2px;
  color: rgba(var(--color-light), 1);
  line-height: 1;
  font-weight: 600;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  background-color: rgba(var(--color-accent), 1);
  border-color: transparent;
  border-width: 1px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  height: 3rem;
  display: inline-flex;
  text-decoration: none;
}

.btn-g-add:hover {
  background-color: rgba(var(--color-accent-hover), 1);
}

.t-header {
  background-color: #f7f8f9;
}

.admin-container {
  background-color: #f3f4f6;
  /* min-height: 100vw; */
}

.loading-page {
  margin-top: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-box {
  height: 100px;
  width: 100px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.group-action {
  align-items: center;
  width: auto;
  display: inline-flex;
  cursor: pointer;
}



.action-delet {
  color: rgba(239, 68, 68, 1);
  height: 100%;
  width: 100%;
  z-index: 30;
  appearance: none;
}

.action-eye {
  color: rgba(var(--color-accent), 1);
  height: 100%;
  width: 100%;
  z-index: 30;
  appearance: none;
}

.action-delet i {
  z-index: 10;
}

.action-edit {
  color: rgba(0, 0, 0, 0.87);
}

.delet-dialog {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box-delete {
  min-width: 30rem;
  background-color: rgba(var(--color-light), 1);
  padding: 1rem;
  padding-bottom: 1.5rem;
  min-width: 24rem;
  border-radius: 0.75rem;
  display: flex;
  flex-direction: column;
}

.box-delete1 {
  background-color: rgba(var(--color-light), 1);
  padding: 1rem;
  padding-bottom: 1.5rem;
  min-width: 24rem;
  border-radius: 0.75rem;
  display: flex;
  flex-direction: column;
  max-height: 500px;
  overflow: scroll;
}

.box-delete>i,
.box-delete1>i {
  color: rgba(var(--color-accent), 1);
  width: 3rem;
  height: 3rem;
  margin-top: 1rem;
  margin: auto;
  font-size: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box-delete p {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 700;
}



.detet-group {
  justify-content: space-around;
  align-items: center;
  width: 100%;
  display: flex;
  margin-top: 2rem;
}

.m0 {
  margin: 0;
  width: 150px;
}

.red {
  background-color: rgba(220, 38, 38, 1);
}

.red:hover {
  background-color: rgba(185, 28, 28, 1);
}

.group-titre {
  padding-top: 2rem;
  padding-bottom: 2rem;

  border-bottom: 1px solid rgba(var(--color-border-base), 1);
  display: flex;
  color: rgba(var(--text-heading), 1);
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.group-g-modifcation {
  padding-bottom: 2rem;
  border-color: rgba(var(--color-border-base), 1);
  border-bottom: 1px solid rgba(var(--color-border-base), 1);
  flex-wrap: wrap;
  display: flex;
}

.g-g-m-t {
  width: 33%;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.g-g-m-content {
  width: 60%;
  margin-top: 2rem;
  padding: 2rem;
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  background-color: rgba(var(--color-light), 1);
  border-radius: 5px;
  position: relative;
}

.custom-file-upload {
  border-color: rgba(var(--color-border-base), 1);
  border-style: dashed;
  border-width: 2px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  display: flex;
  height: 9rem;
  width: 100%;
}

.img-show {
  margin-top: 10px;
  width: 4rem;
  height: 4rem;
  border: 1px solid rgba(var(--color-accent), 1);
}

.input-form {
  margin-bottom: 1.25rem;
  display: flex;
  flex-direction: column;
}

.input-form label {
  margin-bottom: 10px;
  color: rgba(var(--text-base-dark), 1);
  line-height: 1;
  font-weight: 600;
  font-size: 0.875rem;
}

textarea {
  padding: 1rem;
}

.input-form>input,
.input-form>select {
  height: 3rem;
  appearance: none;
  border-radius: 5px;
  border-width: 1px;
  border-color: rgba(var(--color-border-base), 1);
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: rgba(var(--text-heading), 1);
}

.input-form input:focus {
  border-color: rgba(var(--color-accent), 1);
}

.m {
  margin-right: 10px;
}

.group-g-modifcation::last-child {
  border: none !important;
}

.group-button {
  text-align: right;
  margin-top: 1rem;
}

.group-b-b {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.3s;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform, filter, backdrop-filter,
    -webkit-backdrop-filter;
  outline: 2px solid transparent;
  outline-offset: 2px;
  color: rgba(var(--text-base), 1);
  line-height: 1;
  font-weight: 600;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  background-color: transparent;
  border-color: rgba(var(--color-border-400), 1);
  border-width: 1px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  height: 3rem;
  display: inline-flex;
  -webkit-appearance: button;
  cursor: pointer;
  background-image: none;
  text-transform: none;
}

a {
  text-decoration: none;
}

.hide_me {
  display: none;
}

.recptipn-container {
  padding-top: 10rem;
  overflow: scroll;
  max-height: 80%;

  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.recptipn-container>article {
  padding: 1rem;
  border-radius: 0.75rem;
  background-color: rgba(var(--color-light), 1);


  position: relative;
}

.rece-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
  padding: 1rem;
}

.reception-bar-add {
  width: -webkit-fill-available;
  background-color: white;
  padding: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-form>button {
  width: 100%;
}

.input-form datalist {
  width: 100%;
  min-width: 6rem;
}

.command-total {
  padding: 1rem;
  font-weight: 900;
  display: flex;
  justify-content: end;
  width: 100;
}

.rec-date {
  font-weight: 900;
}

.flex-center {
  justify-content: center !important;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: start;
}



.color_primir {
  color: #163C60;
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 5px;
}

.pd {
  padding: 10px;
}

.sold_positive {
  color: rgba(var(--color-accent), 1) !important;
}

.sold_nigative {
  color: rgba(239, 68, 68, 1) !important;
}

.textCenter {
  text-align: center;
}

.ml-10 {
  margin-left: 10px;

}

.not_found {
  height: 50%;
  width: 50%;
}

.not_found_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.menu_mobile {

  display: none;
  cursor: pointer;
}

.container_commande_update {
  padding-top: 100px;
  flex-direction: row;
  display: flex;
  gap: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.container_commande_update .side_detail {
  width: 30%;

}

.side_detail {
  padding: 10px;
  border-radius: 20px;
  background-color: #fff;
  min-height: 100px;
  display: flex;
  flex-direction: column;
}

.commande_content {
  width: 70%;
  padding: 10px;
  border-radius: 20px;
  background-color: #fff;
  min-height: 100px;
  display: flex;
  flex-direction: column;
}


@media (max-width: 700px) {
  .container_commande_update {
    flex-direction: column;
  }

  .container_commande_update .side_detail {
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
  }

  .reception-bar-add {
    flex-direction: column;
  }

  .rece-header {
    flex-direction: column;
  }

  .menu_mobile {

    display: block;
  }

  .d-container {
    padding-left: 0;
  }

  .dash-secend {
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: column;

    /* border-radius: 5px; */
    /* margin-bottom: 1.5rem; */
  }

  .info-dash {
    padding: 10px;
    display: block;
  }

  .dash-table {
    position: relative;
    margin: 0;
    padding: 0;
    overflow-x: scroll;
    width: 100%;
  }

  .dash-pie {
    background-color: white;
    width: 100%;
  }

  .dashbord-content {
    display: flex;
    flex-direction: column;
  }

  .dashbord-result {
    margin-top: 20px;
  }

  .navbar-container {
    justify-content: center;
  }

  .dashbord-page {
    margin-bottom: 24px;
    height: auto !important;
  }

  .admin-content {
    padding-inline-start: 0px
  }

  .admin-main {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .admin-aisde {
    position: relative;
    margin-top: 20px;
    width: 80%;
    padding-bottom: 1rem;
    height: auto;
    overflow: scroll;
  }

  .admin-g-s {
    flex-direction: column;
  }

  .admin-g-search {
    flex-direction: column;
    margin-inline-start: 0;
    width: 100%;
  }

  .btn-g-add {
    margin-inline-start: 0;
    margin-top: 10px;
  }

  .g-g-m-t,
  .g-g-m-content {
    width: 100%;
  }

  .btn-g-add {
    margin-left: 10px;
  }
}